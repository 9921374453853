'use strict';

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var MAIN = {};

  MAIN.setMenu = function()
  {
    var mql = window.matchMedia('screen and (max-width: 768px)');

    function toggleMenu() {
      $('.bar').toggleClass('animate');
      $('body').toggleClass('is-open');
    }

    function collapseMenu() {
      if ($('body').hasClass('is-open')) {
        $('.bar').toggleClass('animate');
        $('body').toggleClass('is-open');
      }
    }

    function checkBreakPoint(mql) {
      if (mql.matches) {
        $('.nav-menu, .list-nav a').on('click', toggleMenu);
      } else {
        $('.nav-menu, .list-nav a').off('click', toggleMenu);
      }
    }

    mql.addEventListener("change", checkBreakPoint);
    checkBreakPoint(mql);
  };

  MAIN.setScroll = function()
  {
    $('a[href*="#"]').on('click', function(e) {
      e.preventDefault();
      if ($(this).hasClass('continue')) {
        $('html, body').animate({
          scrollTop: $($(this).attr('href')).offset().top},
          500, 'swing');
          return false;
      } else {
        $('html, body').animate({
          scrollTop: $($(this).attr('href')).offset().top -60},
          500, 'swing');
          return false;
      }
    });

    $(window).on('scroll', function(){
      var s = $(this).scrollTop();
      var p = $('.pagetop');
      if (s === 0) {
        p.removeClass('show').addClass('hide');
      } else if (s >= 1) {
        p.removeClass('hide').addClass('show');
      }
    }).trigger('scroll');
  };

  MAIN.setSVGFallback = function()
  {
    if (!feature.svg) {
      $('img').each(function() {
        $(this).attr('src', $(this).attr('src').replace(/\.svg/gi,'.png'));
      });
    }
  };

  MAIN.setIas = function()
  {
    var ias = $.ias({
      container:      '.ias-container',
      item:           '.ias-item',
      pagination:     '.posts-navigation',
      next:           '.nav-previous > a',
      negativeMargin: 600
    });

    ias.extension(new IASSpinnerExtension({
      src: '/assets/images/spinner.svg'
    }));

    var lang = $('html').attr('lang');

    if (lang === 'ja') {
      ias.extension(new IASTriggerExtension({
        text: 'もっと見る <i class="icon-chevron-down"></i>'
      }));
      ias.extension(new IASNoneLeftExtension({
        text: 'すべて表示されています'
      }));
    } else if (lang === 'en-US') {
      ias.extension(new IASTriggerExtension({
        text: 'More <i class="icon-chevron-down"></i>'
      }));
      ias.extension(new IASNoneLeftExtension({
        text: 'You reached the end.'
      }));
    }
  };

  MAIN.setExternalLink = function()
  {
    $('a').filter(function () {
      return this.hostname !== window.location.hostname;
    }).attr('target', '_blank');

    $(document).on('click', 'a[href$=".pdf"]', function() {
      $(this).attr('target', "_blank");
    });
  };

  MAIN.setCover = function()
  {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var SHADAN = {
    // All pages
    'common': {
      init: function() {
        MAIN.setMenu();
        MAIN.setScroll();
        MAIN.setSVGFallback();
        MAIN.setExternalLink();
      },
      finalize: function() {
        MAIN.setIas();
      }
    },
    'home': {
      init: function() {
        MAIN.setCover();
        window.addEventListener('resize', MAIN.setCover);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = SHADAN;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
